import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {modalActions} from "../_actions";

function addZero(i) {
    if (i < 10) {
        i = "0" + i;
    }
    return i;
}

class HelpPage extends React.Component {

    componentDidMount() {
    }

    handleClickManual(){
    }

    render() {
        const {modal, sockets} = this.props;
        let hourDiff  = sockets.hourDiff;
        let nowTime = new Date();
        nowTime.setHours(nowTime.getHours() + hourDiff);
        // console.log(modal.isShowManual)
        return (
            <div className="manualList modal_layer" style={{display:modal.isShowManual?"block":"none"}}>
                <div className='help_title'>게임설명</div>
                <dl className='help_content'>
                    <dt class="sub_title">파워볼 데이터 및 제공시간 안내</dt>
                    <dd>■ 파워볼을 기준으로 진행되면, 일반볼합과 파워볼의 숫자로 결과가 정해집니다.</dd>
                    <dd>■ 24시간 5분 단위 일 288회차 진행</dd>
                    <br/><br/>
                    <dt className="sub_title">파워볼 결과 처리안내</dt>
                    <dd>■ 파워볼 홀짝 홀(1,3,5,7,9) 짝(0,2,4,6,8)</dd>
                    <dd>■ 파워볼 언오버 언더(0,1,2,3,4) 오버(5,6,7,8,9)</dd>
                    <dd>■ 파워볼 구간 A(0,1,2), B(3,4), C(5,6), D(7,8,9)</dd>
                    <dd>■ 일반볼 숫자합 홀짝은 모든 수의 합을 기준으로 끝자리 홀(1,3,5,7,9), 짝(0,2,4,6,8)</dd>
                    <dd>■ 일반볼 숫자합 언오버는 모든 수의 합을 기준으로 언더(72이하), 오버(73이상)</dd>
                    <dd>■ 일반볼 숫자합 대중소는 모든 수의 합을 기준으로 대(81-130), 중(65-80), 소(15-64)</dd>
                    <dd>■ 일반볼 숫자합 구간은 모든 수의 합을 기준으로 A(15-35), B(36-49), D(58-65), E(66-78), F(79-130)</dd>
                </dl>
                <div className="btn_close" id="game_info_close" onClick={this.handleClickCloseBtn.bind(this)}></div>
            </div>
        );
    }

    handleClickCloseBtn(){
        const {dispatch, modal} = this.props;
        dispatch(modalActions.showManual(!modal.isShowManual))
    }

}

function mapStateToProps(state) {
    const { modal, sockets } = state;
    return {
        modal,
        sockets
    };
}

const connectedPage = connect(mapStateToProps)(HelpPage);
export { connectedPage as HelpPage };
