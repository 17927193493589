import React, { Component } from 'react';
import { connect } from 'react-redux';

class LoginBox extends Component {

    state ={
        soundOn: true,
    };

    constructor(props){
        super(props);
    }

    componentDidMount(){
    }

    componentWillReceiveProps(nextProps){
    }

    handleSignup(){
        window.alert("이벤트 기간만 가입됩니다.");
    }

    render(){
        const {soundOn} = this.state;
        return (
            <div className="po_top_loginbox">
                <div className="my-form">
                    <div>
                        <div className="single-input">
                            <span><i className="fas fa-user"></i></span>
                            <input type="text" placeholder="아이디"/>
                        </div>
                        <div className="single-input">
                            <span><i className="fas fa-unlock"></i></span>
                            <input type="password" placeholder="패스워드"/>
                        </div>

                        <div className="single-input submit-btn">
                            <button className='login' >로그인</button>
                            <button className='signup' onClick={this.handleSignup.bind(this)}>회원가입</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { modal, sockets } = state;
    return {
        modal,
        sockets
    };
}


const connectedLoginBox = connect(mapStateToProps)(LoginBox);
export {connectedLoginBox as LoginBox};
