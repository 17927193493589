import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { store } from './_helpers';
import { App } from './App';

render(
    <Provider store={store}>
        <Router>
          <Route component={App} />
        </Router>
    </Provider>,
    document.getElementById('root')
);
