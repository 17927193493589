import React, { Component } from 'react';
import { connect } from 'react-redux';
import { modalActions,  soundActions } from '../_actions';
import { soundPlayers } from '../_helpers';

class HeaderBar extends Component {

    state ={
        soundOn: true,
    };

    constructor(props){
        super(props);
    }

    componentDidMount(){
        let soundOn = JSON.parse(localStorage.getItem("soundOn"));
        if(soundOn === undefined) soundOn = true;
        this.setState({soundOn: soundOn});
        if(soundOn === false){
            soundPlayers.readySoundPlayer.mute(true);
            soundPlayers.ballSoundPlayer.mute(true);
        }else{
            soundPlayers.readySoundPlayer.mute(false);
            soundPlayers.ballSoundPlayer.mute(false);
        }

    }

    componentWillReceiveProps(nextProps){
    }

    handleClickBtnSound(){
        const {soundOn} = this.state;
        if(soundOn === true){
            soundPlayers.readySoundPlayer.mute(true);
            soundPlayers.ballSoundPlayer.mute(true);
        }else{
            soundPlayers.readySoundPlayer.mute(false);
            soundPlayers.ballSoundPlayer.mute(false);
        }
        localStorage.setItem("soundOn", soundOn !== true);
        this.setState({soundOn: soundOn !== true});
    }

    handleClickBtnHelp(){
        const {dispatch, modal} = this.props;
        dispatch(modalActions.showManual(!modal.isShowManual))
    }

    render(){
        const {soundOn} = this.state;
        return (
            <div className="po_top_home">
                <a className="home_icon" href='http://nexenpw.com/' target='_blank'></a>
                <div className="help_icon" onClick={this.handleClickBtnHelp.bind(this)}></div>
                <div className={soundOn?"sound_active": "sound_inactive"} onClick={this.handleClickBtnSound.bind(this)}></div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { modal, sockets } = state;
    return {
        modal,
        sockets
    };
}


const connectedHeaderBar = connect(mapStateToProps)(HeaderBar);
export {connectedHeaderBar as HeaderBar};
