import React from 'react';
import { connect } from 'react-redux';
//import 'react-toastify/dist/ReactToastify.css';
import { GamePage } from '../GamePage';
import { HelpPage } from '../HelpPage';

require('../_assets/css/reset.css');
require('../_assets/css/score/ui_game/banner.css');
//require('../_assets/css/score/ui_game/powerball.css');
require('../_assets/css/score/ui_game/powerball_new.css');
require('../_assets/css/score/ui_game/common.css');
require('../_assets/css/score/ui_game/layoutc1fd.css');
require('../_assets/css/score/ui_game/room_newlist.css');

class App extends React.Component {

    constructor(props) {
        super(props);
        const { dispatch } = this.props;
    }

    componentWillMount(){
       const {dispatch} = this.props;
    }

   componentWillUpdate(nextProps) {
   }

    render() {
        return (
                <div>
                  <div>
                      {/*
                      <div style={{"position":"absolute", "top":"665px", "z-index":"1000000"}}>
                          <a href="http://euro3min.com/euro3min/" target="_new">
                              <img src={require("../_assets/img/score/ui_game/foot_notice.png")}/>
                              <div className={'foot-notice'}>
                                EURO연합에서 운영하는 유로3분파워볼 결과만 연출해 보여줍니다.<br/>
                                EURO연합 http://euro3min.com/ 공식 홈페이지를 참고해주세요.
                              </div>
                          </a>
                      </div>
                      */}
                      <HelpPage/>
                      <GamePage/>
                  </div>
                </div>
        );
    }
}

function mapStateToProps(state) {
    return {
    };
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App };
